import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert,
    ProgressBar,
    Modal
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom';


// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function EventList() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [eventDue, setEventDue] = useState(new Date())

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    const [eventList, setEventList] = useState<any[]>([])

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }

    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const [isLoading, setLoading] = useState(false)

    const getAllEvents = () => {
        setLoading(true)

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_list}/${companyId}/${showOlder}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventList(response)


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }



    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };

    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const onSubmit = (data: any) => {

        console.log('< > vai1')
        console.log(data)

        setDisableSign(true)

        const companyId = sessionStorage.getItem('company')
        const dateFormated = new Date(eventDue)


        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_create}/${data.eventId}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "name": data.name,
                "eventDate": dateFormated,
                "isActive": data.status ? true : false,
            })
        })
            .then((res) => res.json())
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }



    const [currentEventToEdit, setCurrentEventToEdit] = useState({
        name: '',
        eventId: '',
        status: 'active'
    })
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditModal = (eventName, eventDate, eventId, eventStatus) => {
        console.log('-->', eventStatus)
        setEventDue(new Date(eventDate))
        setCurrentEventToEdit({
            name: eventName,
            eventId: eventId,
            status: eventStatus
        })
        setShowEditModal(true)
    }
    const hideEditModal = () => setShowEditModal(false)

    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    const [eventDate, setEventDate] = useState<any>()
    const [showOlder, setShowOlder] = useState(false)
    const [eventName, setEventName] = useState('')

    const searchAllEvents = (data: any) => {

        const searchEvent = {}

        data.preventDefault()
        setLoading(true)

        const companyId = sessionStorage.getItem('company')

        if (eventName) {
            searchEvent['eventName'] = eventName
        }

        if (eventDate) {
            searchEvent['eventDate'] = eventDate.toISOString().split('T')[0]
        }



        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_list}/${companyId}/${showOlder}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(searchEvent)
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventList(response)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    useEffect(() => {
        getAllEvents()
    }, [])

    const handleSync = () => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_create}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            }
        })
            .then((res) => res.json())
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <Card className="Recent-Users">
                <Card.Header>
                    <Form onSubmit={searchAllEvents}>
                        <Row className="align-items-center">
                            <Col sm={12} md={1}>
                                <Card.Title as="h5">Eventos</Card.Title>
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                    Evento
                                </Form.Label>
                                <Form.Control id="inlineFormInputName" placeholder="Nome do evento"
                                    onChange={(e) => setEventName(e.target.value)}
                                />
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                    Data
                                </Form.Label>
                                <DatePicker
                                    className="form-control"
                                    locale="pt-br"
                                    dateFormat="dd/MM/yyyy"
                                    selected={eventDate}
                                    onChange={(date: Date) => setEventDate(date)}
                                />
                            </Col>
                            <Col sm={12} md={2}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Mostrar anteriores?"
                                    onChange={() => {
                                        setShowOlder(!showOlder)
                                    }}
                                />
                            </Col>


                            <Col sm={12} md={3} lg={2} >
                                <Button className='w-100' type="submit">Pesquisar</Button>
                            </Col>
                        </Row>
                    </Form>

                </Card.Header>
                <Card.Body className="px-0 py-2">
                    <Table responsive hover>
                        <tbody>

                            {eventList &&
                                eventList.map((item: any, index) => (

                                    <tr key={index} className="unread">
                                        <td>

                                            <Link to={`/events/${item.id}`} className="label theme-bg5 text-white f-12">
                                                Acompanhar
                                            </Link>
                                            <h6 className="text-muted biometry-status m-l-20 m-t-10">
                                                status
                                                <i className={`fa fa-circle text-c-${item.isActive ? 'green' : 'red'} f-12 m-l-15`} />
                                            </h6>
                                        </td>
                                        <td>
                                            <h6 className="mb-1">{dateConvert(item.eventDate)}</h6>
                                        </td>
                                        <td>
                                            <h6 className="mb-1">{item.name}</h6>
                                        </td>
                                        {/* <td>
                                        <h6 className="text-muted biometry-status m-t-10">
                                            fase: [ {item.status} ]
                                        </h6></td> */}
                                        <td>

                                            {item.biometricTotal && userSyncProgress(item.biometricTotal, item.biometricLeft) < 100 ?

                                                (userSyncProgress(item.biometricTotal, item.biometricLeft) < 0) ?
                                                    <ProgressBar variant="danger" label={'erro'} now={100} /> :
                                                    (<ProgressBar animated label={`${userSyncProgress(item.biometricTotal, item.biometricLeft)}%`} now={userSyncProgress(item.biometricTotal, item.biometricLeft)} />) :
                                                userSyncProgress(item.biometricTotal, item.biometricLeft) > 0 ?
                                                    (
                                                        <ProgressBar variant="success" label={'completa'} now={100} />
                                                    ) : ''
                                            }
                                        </td>

                                        <td>
                                            <Link to={`/events/providers/${item.id}`} className="label theme-bg text-white f-12 m-l-15">
                                                Grupos
                                            </Link>
                                            <Link to="#" onClick={() => { handleEditModal(item.name, item.eventDate, item.id, item.isActive) }} className="label theme-bg2 text-white f-12">
                                                Editar
                                            </Link>
                                            {/* <Link to="#" onClick={() => { handleSync() }} className="label theme-bg text-white f-12 m-l-15">
                                                Sincronizar
                                            </Link> */}

                                        </td>
                                    </tr>

                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={showEditModal} onHide={hideEditModal}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <Row>
                            <Col>

                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label onClick={() => { signOut() }}>Nome</Form.Label>
                                        <Form.Control
                                            defaultValue={currentEventToEdit.name}
                                            placeholder="Nome do Evento/Atração"
                                            {...register('name', { required: true })}
                                        />
                                        {errors.name && (
                                            <span className="label-error">
                                                Você precisa informar um Nome.
                                            </span>
                                        )}
                                        <Form.Control
                                            type='hidden'
                                            defaultValue={currentEventToEdit.eventId}
                                            {...register('eventId')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Data / hora</Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            locale="pt-br"
                                            showTimeInput={true}
                                            timeInputLabel='Hora'
                                            dateFormat="dd/MM/yyyy : HH:mm"
                                            selected={eventDue}
                                            onChange={(date: Date) => setEventDue(date)}
                                        />
                                        {errors.dueDate && (
                                            <span className="label-error">
                                                Você precisa informar uma data válida
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            defaultChecked={currentEventToEdit.status ? true : false}
                                            label="Ativo?"
                                            {...register('status')}
                                        />
                                    </Col>
                                </Row>


                                {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}

                            </Col>
                        </Row>

                        {newUser.set && (
                            <>
                                <hr />
                                <Row>
                                    <Col className="qr-wrapper">
                                        <h3>Usuário cadastrado com sucesso!</h3>
                                        <p>
                                            Login: <strong>{newUser.login}</strong>
                                            <br />
                                            Senha provisória: <strong>{newUser.password}</strong>
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={hideEditModal}>
                            Fechar
                        </Button>
                        <Button variant="success" type='submit'>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
