import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Table, Modal, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import noavatar from '../../assets/images/user/avatar-0.png'
import clubLogo from '../../images/club_logo.svg'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const MySwal = withReactContent(Swal)
function ShowEventProvider() {
    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const [pridedUserList, setPridedUserList] = useState<any[]>([])

    const [isBepass, setIsBepass] = useState(false)

    const [currentUserImage, setCurrentUserImage] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [userList, setUserList] = useState<any[]>([
        {
            "name": "SecCutiry",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            "userAvatar": clubLogo,
            coordinator: "Lucas Chaves",
            "biometricTotal": "150",
            "biometricLeft": "50",
            "syncStatus": [

            ]
        }
    ])


    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);

    const [showUserBlock, setShowUserBlock] = useState(false);

    const [userFormattedId, setUserFormattedId] = useState('');

    const handleCloseUserBlock = () => setShowUserBlock(false);
    const handleShowUserBlock = () => setShowUserBlock(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)


    const [userIdCheckIn, setUserIdCheckIn] = useState('');


    const getAllUsers = (eventId, supplierId) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.with_biometry_status}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response.users)

                const enabledCount = response.users.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const [userName, setUserName] = useState('')
    const [selectBiometry, setSelectBiometry] = useState('')
    const [messageType, setMessageType] = useState('')

    const searchAllUsers = (e) => {
        setLoading(true)

        const data = {}

        e.preventDefault()

        if (userName) {
            data['name'] = userName
        }

        if (selectBiometry) {
            data['biometry'] = selectBiometry
        }
        if(selectBiometry === 'dont') {
            getUsersWithNoBiometry()
        } else if(selectBiometry === 'have') {
            getUsersWithBiometry()
        } else {
            getUsersWithoutBiometry()
        }
    }

    const [eventData, setEventData] = useState<any>({})

    const getEventDetails = (eventId) => {
        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_full_data}/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventData(response)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const [eventNumber, setEventNumber] = useState(0)
    const [entranceList, setEntranceList] = useState<any[]>([])

    const verifyEntrance = (providerId: any) => {
        return entranceList.findIndex(obj => obj.userId === providerId) >= 0 ? entranceList[entranceList.findIndex(obj => obj.userId === providerId)].createdAt : null
    }


    const getEventEntrances = (eventId, supplierId) => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_access_detailed}/${eventId}/${supplierId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                if (eventNumber !== response) {
                    setEventNumber(response.length)
                    setEntranceList(response)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const { eventId, supplierId } = useParams()



const [messageUserIdList, setMessageUserIdList] = useState<any[]>([])
 const callModalMessageSent = () => {
     MySwal.fire({
         title:'Envio de mensagem em lote',
         text:`Mensagem Enviada Com Sucesso!`,
         icon:'success',
         confirmButtonColor: '#7b7e8d',
     })
 }
    const callModalSendMessage  = (e) => {
        e.preventDefault()
     if(messageType != 'null' && messageType != '' ){
         MySwal.fire({
             title:'Envio de mensagem em lote',
             text:`Tem certeza que deseja enviar a mensagem de onboarding para todas as pessoas sem biometria cadastrada via ${messageType}?`,
             icon:'warning',
             // confirmButtonColor: '#242a3c',
             showCancelButton: true,
             confirmButtonText: 'Sim! Pode enviar',
             cancelButtonText: 'Cancelar',
         }).then((res)=>{
             if(res.isConfirmed) {
                 sendOnboardingMessageToAllUsers(messageType)
             }
         })
     } else {
         MySwal.fire({
             title:'Envio de mensagem em lote',
             text:`É necessário selecionar um tipo de mensagem!`,
             icon:'error',
             confirmButtonColor: '#7b7e8d',
         })
     }

    }
const sendMessage = (e) => {
    if (confirm("Confirmar envio?") == false) {
        return false
    }
    setLoading(true)

    const data = {
        users: messageUserIdList,
        type: messageType
    }

    e.preventDefault()

    if(!messageUserIdList){
        console.log('<><> oooo <><>')
        return false
    }

    fetch(`${BASE.api_user.base_url}${BASE.api_user.send_messages}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify(data)
    })
        .then((resg) => resg.json())
        .then((response) => {
            setLoading(false)
            // console.log('hecho!')
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);

        })
        .catch((error) => {
            setLoading(false)
            console.log(error)
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);
        })
}


    const getUsersWithoutBiometry = () => {

        fetch(`${BASE.api_user.base_url}${BASE.api_user.with_biometry_status}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response.users)

                const enabledCount = response.users.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const getUsersWithNoBiometry = () => {
        setLoading(true)
        fetch(`${BASE.api_user.base_url}${BASE.api_user.with_no_biometry}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response.users)

                const enabledCount = response.users.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const getUsersWithBiometry = () => {
        setLoading(true)
        fetch(`${BASE.api_user.base_url}${BASE.api_user.with_biometry}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {

                setPridedUserList(response.users)
                setLoading(false)
                const enabledCount = response.users.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const sendOnboardingMessageToAllUsers = (messageType) => {
        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_onboarding_messages_all_users}/${messageType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                response.status === 200 ? callModalMessageSent():''
            })
            .catch((error) => {
                console.log(error)
            })

    }


    const whois = sessionStorage.getItem('name')

    useEffect(() => {
        getEventDetails(eventId)
        getAllUsers(eventId, supplierId)
        getEventEntrances(eventId, supplierId)
        getUsersWithoutBiometry()

        if(whois && whois === 'BePass'){
            setIsBepass(true)
        }

        // setInterval(() => {
        //     getEventEntrances(eventId, supplierId)
        // }, 10000)
    }, [])

    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleInputs = () => {
        console.log('oie!')
        if (userIdCheckIn) {
            fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.create_event_access}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                },
                body: JSON.stringify({
                    userId: userIdCheckIn,
                    eventId,
                    supplierId
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    const [showToast, setShowToast] = useState(false);
    const sendEmail = (data) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.firstName,
                email: data.email,
                token: data.token,
                subject: 'Cadastro para acesso Arena MRV',
                template: 'bepass-default-cam'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setShowToast(true)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const excludeFromEvent = (userId:any) => {

        if (confirm("Confirmar exclusão?") == false) {
        return false
        }

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_remove_user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                eventId,
                userId
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                window.location.reload()
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    const sendSingleMessage = (type, userId) => {
        const data = {
            users: [userId],
            type: type
        }


        if (confirm("Confirmar envio?") == false) {
            return false
            }

        setLoading(false)

            fetch(`${BASE.api_user.base_url}${BASE.api_user.send_messages}`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(data)
            })
            .then((resg) => resg.json())
            .then((response) => {
            setLoading(false)
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);

            })
            .catch((error) => {
            setLoading(false)
            console.log(error)
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);
            })
    }

    const removeBiometry = (userId: any) => {
      setLoading(true)
      if (!confirm('Tem certeza?')) {
          setLoading(false)
          return false
      }
      fetch(`${BASE.api.base_url}${BASE.api.remove_biometry}`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              Authorization: CONFIG.API_KEY
          },
          body: JSON.stringify({
              user: userId
          })
      })
          .then((resg) => resg.json())
          .then((response) => {
              setLoading(false)
              generateLog({ affectationId: userId })
              getAllUsers(eventId, supplierId)
          })
          .catch((error) => {
              setLoading(false)
              console.log(error)
          })
  }


const generateLog = (data: any, bioUse:any = 'biometry delete for game') => {
    const requestData = {
        userId: sessionStorage.getItem('token'),
        detail: {
            rule: sessionStorage.getItem('role'),
            process: bioUse
        },
        affectation: data.affectationId
    }
    fetch(`${BASE.api.base_url}${BASE.api.log_create}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify(requestData)
    })
}

const getUserImage = (token: any) => {
    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.get_image_biometry}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify({
            token: token
        })
    })
        .then((resg) => resg.json())
        .then((response) => {

           const userImage = response.image ? `data:image/png;base64,${response.image}`:`https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=https://penaareia.bepass.com.br/?token=${token}`
            setLoading(false)
            generateLog({ affectationId: token }, 'download user Image')
            setCurrentUserImage(response.image)

            MySwal.fire({
                html:  <img
                  src={userImage}
                  className="qr-code"
                  alt="Pessoa"
                />,
                confirmButtonColor: '#ccc',
              })
        })
        .catch((error) => {
            setLoading(false)
            console.log(error)
        })
}

    return (
        <>
            <Row>
                <Col md={9} xl={9}>
                    <Card className="Recent-Users">
                        <Card.Header>
                            <Row>
                                <Col sm={6}>
                                    <Card.Title as="h5">
                                        <i className={`fa fa-circle text-c-green f-12 m-r-15`} />
                                        {eventData.name}</Card.Title>
                                    <h6 className="text-muted biometry-status m-t-10">
                                        {dateConvert(eventData.eventDate)}
                                    </h6>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                </Col>
            
                    <Col md={12} xl={12}>
                    <Card className="Recent-Users">
                        <Card.Header>

                            <Form onSubmit={(searchAllUsers)}>
                                <Row className="align-items-center">
                                    <Col sm={12} md={3}>
                                        <Card.Title as="h5">Pessoas selecionadas {pridedUserList.length}</Card.Title>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Control placeholder="Nome"
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </Col>

                                    <Col sm={12} md={3}>
                                        <select
                                            // value={userData.role}
                                            className="form-control"
                                            onChange={(e) => setSelectBiometry(e.target.value)}
                                        >
                                            <option selected value="null">Biometria : Todos</option>
                                            <option value="dont">Sem Biometria</option>
                                            <option value="have">Com Biometria</option>

                                        </select>
                                    </Col>



                                    <Col sm={12} md={3} lg={2} >
                                        <Button className='w-100' type="submit">Pesquisar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            {isLoading &&
                                <div className='local-loader'>
                                    <i
                                        className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
                                        title="oiee"
                                    />
                                </div>}
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Checkin</th>
                                        <th>Foto</th>
                                        <th>CPF</th>
                                        <th>Nome</th>
                                        <th>Contatos</th>
                                        <th>Biometria</th>
                                         {/* <th>Ações</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {pridedUserList &&
                                        pridedUserList.map((user: any, index) => (

                                            <tr key={index} className="unread">
                                                <td>
                                                    {/*  onClick={() => { setUserIdCheckIn(user.userId); handleShowConfirm() }} */}
                                                    <h6 className="text-muted biometry-status m-t-10">
                                                        {verifyEntrance(user.userId) !== null ? dateConvert(verifyEntrance(user.userId)) : 'Não Feito'}
                                                        <i className={`fa fa-circle text-c-${verifyEntrance(user.userId) !== null ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>

                                                    {/* {verifyEntrance(user.userId) !== null ? `` : (
                                                        <Link to="#" onClick={() => { setUserFormattedId(user.userId.replace(/-/g, '')); handleShowUserBlock() }} className="link-coordinator text-c-yellow m-l-15">
                                                            bloquear
                                                        </Link>
                                                    )} */}

                                                </td>
                                                <td>
                                                      <img onClick={() => {user.biometry !== 'no' ? getUserImage(user.token):console.log('no')}} className="rounded-circle" style={{ width: '40px' }} src={
                                                          user.biometry !== 'no'
                                                              // ? `data:image/png;base64,${user.biometry}`
                                                              ? avatar1
                                                              : noavatar
                                                      } alt="activity-user" />
                                                  </td>
                                                <td>
                                                    <h6 className="mb-1">{user.document}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{`${user.firstName} ${user.lastName}`}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{user.email}</h6>
                                                    <span>{user.phoneNumber}</span>
                                                </td>
                                                <td>
                                                    <h6 className="text-muted biometry-status m-t-10" onClick={() => {removeBiometry(user.token)}}>
                                                        {user.hasBiometry === 'have' ? 'OK - Refazer' : 'FALTA'}
                                                        <i className={`fa fa-circle text-c-${user.hasBiometry === 'have' ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>
                                                </td>
                                                {/* <td>
                                                    <Link to="#" onClick={() => sendEmail(user)} className="label theme-bg2 text-white f-12">
                                                        Enviar e-mail
                                                    </Link>
                                                */} <td>

                                                    {/* <Link to="#" onClick={() => sendSingleMessage('email', user.userId)} className="label theme-bg text-white f-12">
                                                        Enviar e-mail
                                                    </Link>

                                                    <Link to="#" onClick={() => sendSingleMessage('whats',user.userId)} className="label theme-bg2 text-white f-12">
                                                        Enviar whatsapp
                                                    </Link>

                                                    <Link to="#" onClick={() => sendSingleMessage('sms',user.userId)} className="label theme-bg5 text-white f-12">
                                                        Enviar sms
                                                    </Link> */}

                                                    {/*<Link to="#" onClick={() => { excludeFromEvent(user.userId) }} className="link-coordinator text-c-red m-l-15">*/}
                                                    {/*    excluir*/}
                                                    {/*</Link>*/}
                                                </td>
                                            </tr>

                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
                <Col md={4} xl={3}>

                {isBepass === true && (
                    <Card>
                    <Card.Body>

                    </Card.Body>
                </Card>
                )}
               </Col>
            </Row>





            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={() => { handleInputs(); handleCloseConfirm() }}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUserBlock} onHide={handleCloseUserBlock}>
                <Modal.Body>
                    UUID: {userFormattedId}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUserBlock}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseUserBlock}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default ShowEventProvider
