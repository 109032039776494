import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert,
    ToastContainer,
    Toast
} from 'react-bootstrap'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link, useParams } from 'react-router-dom';
import Loader from '../../shared/Loader';

const MySwal = withReactContent(Swal)

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function SingleCheckIn() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [showProviderList, setShowProviderList] = useState(false)

    const [disableSign, setDisableSign] = useState(false)

    const [hasSearchUser, setHasSearchUser] = useState(false)
    const [userList, setUserList] = useState<any[]>([])

    const [isLoading, setLoading] = useState(false)

    const [userNotFound, setUserNotFound] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })


    const [providerList, setProviderList] = useState<any[]>([
        {
            "name": "SecCutiry",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            coordinator: "Lucas Chaves",
            "syncStatus": [

            ]
        },
        {
            "name": "Clara Gourmet",
            "access_code": null,
            "token": "$2a$10$szP4h29fFYRZEk3iZZ7iheEE3a4yynOq22iWQT3VP.UtIEvBICafO",
            "externalId": "777",
            "hikId": "963b3fbdf0df4dcd90e7ad699e08c282",
            "active": false,
            coordinator: null,
            "syncStatus": null
        },
        {
            "name": "Trehucchi Jogos",
            "access_code": null,
            "token": "$2a$10$pYZxO/Zswo6cISB3UIy3lu0jcseLl51aoD82p1vVt3FL5Q/vmglB6",
            "externalId": "888",
            "hikId": "00cbdb77a8fb402d97afe7628c8cad75",
            "active": false,
            coordinator: "Romulo Pires",
            "syncStatus": null
        }
    ])


    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }





    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };

    const { eventId } = useParams()

  const generateOnboardingQR = (hasBiometry: string, token: string) =>  {

      if(hasBiometry === 'yes') {
        MySwal.fire({
            title:'Biometria',
            text:'Usuário já possui biometria cadastrada',
            icon:'info',
          confirmButtonColor: '#7b7e8d',
        })
      } else {
        MySwal.fire({
          title: <h2>Aguarde um momento estamos gerando o QRCODE...</h2>,
          html:  <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=https://arenamrv.bepass.com.br/?token=${token}`}
            className="qr-code"
            alt="Palmeiras"
          />,
          confirmButtonColor: '#7b7e8d',
        })
      }


  }
    const manualCheckin = (userIdCheckIn, supplierId) => {


        const currentTotem = sessionStorage.getItem('currentTotem') || 'na'

        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_fixed_manual_checkin}/${userIdCheckIn}/${currentTotem}`, {
            // fetch(`${BASE.api_user.base_url}${BASE.api_user.user_manual_checkin}/${userIdCheckIn}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            }
        })
            .then((res) => res.json())
            .then((response) => {
                setUserList([])
                setShow(true)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const [show, setShow] = useState(false);


    const onSubmit = (data: any) => {

        setLoading(true)
        setUserNotFound(false)

        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_search_name}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
                if (response.users.length < 1) {
                    setUserNotFound(true)
                }
                setUserList(response.users)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setDisableSign(false)
        reset()
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5">Check-In Manual</Card.Title>
                </Card.Header>
                <Card.Body>
                    <>

                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-4">
                                        <Col>
                                            <Form.Label>Pesquise pelo Nome/Sobrenome</Form.Label>
                                            <Form.Control
                                                placeholder="ex.: João"
                                                {...register('name', { required: true })}
                                            />
                                            {errors.name && (
                                                <span className="label-error">
                                                    Você precisa informar um Nome/Sobrenome.
                                                </span>
                                            )}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Button
                                                    disabled={disableSign}
                                                    type="submit"
                                                    className="mb-0 w-100"
                                                >
                                                    Pesquisar
                                                </Button>

                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                                </Form>
                              <Button
                                type="submit"
                                className="mb-0 w-100 btn btn-light"
                                onClick={() => setUserList([])}
                              >
                                Limpar Pesquisa
                              </Button>
                            </Col>
                        </Row>

                        {isLoading &&
                            <Loader />
                        }
                        {userNotFound &&
                            <Alert className="mt-5" variant="warning">
                                Prestador não encontrado
                            </Alert>
                        }

                        {userList &&
                            userList.map((item) => (
                                <>
                                    <hr />
                                    <Row>
                                        <Col className="qr-wrapper">
                                            <h6>{item.firstName} {item.lastName}</h6>
                                            <p>{item.originToken}  | {item.document}</p>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col xs={5}>
                                            <Button
                                                type="submit"
                                                className="mb-0 w-100 btn btn-success"
                                                onClick={() => { manualCheckin(item.id, item.supplierId) }}
                                            >
                                                Dar entrada
                                            </Button>
                                        </Col>
                                      <Col xs={5}>
                                        {item.hasBiometry ==='no'&&
                                          <Button
                                            type="submit"
                                            className="mb-0 w-100 btn btn-light"
                                            onClick={() => { generateOnboardingQR(item.hasBiometry, item.token) }}
                                          >
                                            Gerar QRCode
                                          </Button>
                                        }
                                        {item.hasBiometry ==='yes'&&
                                          <Button disabled
                                            type="submit"
                                            className="mb-0 w-100 btn btn-light"

                                          >
                                           Biometria Ok
                                          </Button>
                                        }
                                      </Col>
                                    </Row>
                                </>
                            ))
                        }

                        <hr />

                    </>
                </Card.Body>
            </Card>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>Entrada Cadastrada</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}
